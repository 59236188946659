import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InputBase, InputBaseProps, Tooltip } from '@mui/material';

import useStyles from './styles';

type Props = Omit<InputBaseProps, 'error'> & {
    error?: string;
    'data-testid'?: string;
};

const BaseInput: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { className, error, 'data-testid': dataTestId, inputProps, ...restProps },
    ref
) => {
    const { classes, cx } = useStyles();

    return (
        <Tooltip
            arrow
            placement="bottom-start"
            title={
                error ? (
                    <div className={classes.error}>
                        <ErrorOutlineIcon className={classes.icon} />
                        <span className={classes.text}>{error}</span>
                    </div>
                ) : null
            }
        >
            <InputBase
                fullWidth
                {...restProps}
                error={!!error}
                inputRef={ref}
                inputProps={{ ...inputProps, 'data-testid': dataTestId }}
                className={cx(classes.input, className)}
            />
        </Tooltip>
    );
};

export default React.memo(React.forwardRef(BaseInput));
