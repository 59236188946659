import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';

import { Button, MenuItem } from '@mui/material';

import TextField from 'app/ui-kit/components/TextField';
import useStores from 'hooks/use-stores';
import { SearchType } from 'main/components/SearchBar/types';
import { ElasticQueryBuilder } from 'modules/elastic';
import { toUtc } from 'pages/Email/components/Scheduler/utils';

import useStyles from './styles';

const DEFAULT_PRESETS = {
    day: {
        label: 'Every day at 10:00',
        value: `0 ${toUtc(10).time} * * *`
    },
    week: {
        label: 'Every Monday at 10:00',
        value: `0 ${toUtc(10).time} * * 1`
    },
    month: {
        label: 'First day of Month at 10:00',
        value: `0 ${toUtc(10).time} 1 * *`
    },
    asap: {
        label: 'As soon as possible',
        value: '30 * * * *'
    }
};

interface Props {
    onSubscriptionSave: () => void;
}

const SubscriptionForm: React.FC<Props> = ({ onSubscriptionSave }) => {
    const { classes } = useStyles();

    const { userStore, searchStore } = useStores();
    const { editingSubscription } = userStore;

    useEffect(() => {
        if (searchStore.type === SearchType.simple) {
            editingSubscription.setQueryType('lucene');
            const query = searchStore.query.trim();
            editingSubscription.setQuery(query);
            editingSubscription.setLinkQuery(`/search?query=${encodeURIComponent(query)}`);
            return;
        }
        if (searchStore.type === SearchType.elastic) {
            const { elasticSearchDriver, elasticQueryBuilder } = searchStore;
            const { query: elasticQuery } = elasticQueryBuilder.getQuery(elasticSearchDriver.state);
            editingSubscription.setQueryType('elastic');
            editingSubscription.setQuery(JSON.stringify(elasticQuery.query));
            editingSubscription.setLinkQuery(
                `/search?state=${ElasticQueryBuilder.stateToString(elasticSearchDriver.requestState)}`
            );
            return;
        }
    }, [editingSubscription, searchStore]);

    useEffect(() => {
        editingSubscription.setCrontab(`0 ${toUtc(10).time} * * *`);
    }, [editingSubscription]);
    useEffect(() => {
        editingSubscription.setEmail(userStore.user.email);
    }, [editingSubscription, userStore.user.email]);

    const handleUserEmailChange = useCallback(({ target }) => editingSubscription.setEmail(target.value), [
        editingSubscription
    ]);

    const handleScheduleChange = useCallback(
        ({ target }) => {
            editingSubscription.setCrontab(target.value);
        },
        [editingSubscription]
    );

    return (
        <div className={classes.root}>
            <div className={classes.title}>Stay informed!</div>
            <div className={classes.subtitle}>Get updates to this search query by email</div>

            <TextField
                className={classes.selector}
                InputProps={{
                    classes: {
                        root: classes.selectorRoot,
                        input: classes.selectorInput
                    }
                }}
                fullWidth
                select
                variant="outlined"
                value={editingSubscription.crontab}
                onChange={handleScheduleChange}
            >
                {Object.entries(DEFAULT_PRESETS).map(([key, preset]) => (
                    <MenuItem value={preset.value} key={key}>
                        {preset.label}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                variant="outlined"
                placeholder="Email"
                label={'Email'}
                error={!!editingSubscription.errorMessage}
                helperText={editingSubscription.errorMessage}
                fullWidth
                className={classes.email}
                value={editingSubscription.email}
                onChange={handleUserEmailChange}
            />

            <div>
                <Button fullWidth className={classes.button} onClick={onSubscriptionSave}>
                    Subscribe
                </Button>
            </div>
        </div>
    );
};

export default observer(SubscriptionForm);
