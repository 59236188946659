import { throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import API from 'app/API';
import useSkipOnMountEffect from 'hooks/use-skip-on-mount-effect';
import { defaultSimpleSearchSuggestions, emptyArray } from 'main/components/SearchBar/constants';
import { SearchFieldValue, SearchType } from 'main/components/SearchBar/types';
import { useElasticAutocomplete } from 'modules/elastic';
import debounce from 'utils/debounce';
import isOkResponse from 'utils/is-ok-response';

const THROTTLE_TIME = 500;
const UI_DELAY = 200;

const defaultSuggestionsByType: Record<SearchType, { value: string }[]> = {
    [SearchType.simple]: defaultSimpleSearchSuggestions.map((value) => ({ value })),
    [SearchType.product]: emptyArray,
    [SearchType.elastic]: emptyArray
};

export default function useSuggestions(value: SearchFieldValue) {
    const type = value.type;
    const query = value.query.trim();
    const software = value.software.trim();

    const [suggestions, setSuggestions] = useState<{ value: string }[]>(defaultSuggestionsByType[type] || emptyArray);
    const [isLoading, setIsLoadingInternal] = useState(false);

    /** Avoiding interface flickering due to fast requests */
    const setIsLoading = useMemo(() => debounce(setIsLoadingInternal, UI_DELAY), []);

    const fetchElasticSearchSuggestions = useElasticAutocomplete();

    const getElasticSearchSuggestions = useMemo(() => {
        const execute = async (query: string) => {
            setIsLoading(true);
            const result = await fetchElasticSearchSuggestions(query).finally(() => setIsLoading(false));
            setSuggestions(result);
        };
        return throttle(execute, THROTTLE_TIME);
    }, [fetchElasticSearchSuggestions, setIsLoading]);

    const getSimpleSearchSuggestions = useMemo(() => {
        const execute = async (query: string) => {
            setIsLoading(true);
            const result = await fetchSimpleSearchSuggestions(query).finally(() => setIsLoading(false));
            setSuggestions(result);
        };
        return throttle(execute, THROTTLE_TIME);
    }, [setIsLoading]);

    const getProductSearchSuggestions = useMemo(() => {
        const execute = async (software: string) => {
            setIsLoading(true);
            const result = await fetchProductSearchSuggestions(software).finally(() => setIsLoading(false));
            setSuggestions(result);
        };
        return throttle(execute, THROTTLE_TIME);
    }, [setIsLoading]);

    const clear = useCallback(() => {
        getSimpleSearchSuggestions.cancel();
        getProductSearchSuggestions.cancel();
        getElasticSearchSuggestions.cancel();
        setIsLoading.clear();
    }, [getProductSearchSuggestions, getSimpleSearchSuggestions, getElasticSearchSuggestions, setIsLoading]);

    useSkipOnMountEffect(() => {
        clear();
        setSuggestions(emptyArray);
        return () => clear();
    }, [type]);

    useEffect(() => {
        switch (true) {
            case SearchType.simple === type && !!query:
                getSimpleSearchSuggestions(query);
                break;
            case SearchType.elastic === type && !!query:
                getElasticSearchSuggestions(query);
                break;
            case SearchType.product && !!software:
                getProductSearchSuggestions(software);
                break;
            case SearchType.simple && !query:
            case SearchType.elastic && !query:
            case SearchType.product && !software:
                clear();
                setSuggestions(defaultSuggestionsByType[type]);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, software, type]);

    return { isLoading, suggestions };
}

async function fetchSimpleSearchSuggestions(query: string): Promise<{ value: string }[]> {
    const response = await API.fetch(API.SEARCH_AUTOCOMPLETE, { query });
    if (isOkResponse(response) && response.data.suggestions.length > 0) {
        return response.data.suggestions.map(([value]) => ({ value }));
    } else {
        return emptyArray;
    }
}

async function fetchProductSearchSuggestions(software: string): Promise<{ value: string }[]> {
    const response = await API.fetch(API.SEARCH_SOFTWARE_AUTOCOMPLETE, { product: software, limit: 10 });
    if (isOkResponse(response) && response.data.result?.length) {
        return response.data.result.map((value) => ({ value }));
    } else {
        return emptyArray;
    }
}
